@import "src/containers/App/App.scss";

.ScrollButton {
  position: fixed;
  background:  $mainColor;;
  right: 10px;
  bottom: 10px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  svg {
    width: 40px;
    cursor: pointer;

    path, g {
      fill: $white;
    }

    #Rettangolo_117-2 {
      stroke: $white;
    }
  }

}