$mainColor: #65C8D0;
$mainGrey: #727272;
$bgGrey: #F9F9F9;
$white: #FFFFFF;
$textTitleColor: #383838;
$accentColor: #F87C00;
$maxWidth: 850px;

@mixin desktop {
  @media only screen and (min-width: 1025px) {
    @content;
  }
}
@mixin smartphone {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 360px) {
    @content;
  }
}

.Content {
  @include smartphone {
    padding: 0 16px;
  }
}

h3 {
  font-size: 18px;
  color: $textTitleColor;
}

h4 {
  font-size: 14px;
  color: $textTitleColor;
  text-transform: uppercase;
}

p {
  color: $mainGrey;
  line-height: 1.4rem;
}

button {
  border: solid 2px $mainColor;
  box-shadow: 1px 1px 4px #00000021;
  color: $mainColor;
  background: none;
  width: 148px;
  margin: 18px auto;
  font-weight: bold;
  border-radius: 4px;
  border-width: 2px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background: $mainColor;
    color: white;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


